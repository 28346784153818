<template>
  <div>
    <el-row>
      <el-col>
        <head-layout
          :head-btn-options="headBtnOptions"
          :head-title="$t('cip.plat.sys.params.title.indexHeadTitle')"
          @head-remove="headRemove"
          @head-add-tabs="headAddTabs"
        ></head-layout>
      </el-col>
    </el-row>

    <!--    :grid-head-btn="gridHeadBtn"-->
    <grid-head-layout
      ref="gridHeadLayout"
      :search-columns="searchColumns"
      @grid-head-search="gridHeadSearch"
      @grid-head-empty="gridHeadEmpty"
    ></grid-head-layout>
    <grid-layout
      ref="gridLayOut"
      :grid-row-btn="gridRowBtn"
      @gird-handle-select-click="selectionChange"
      :table-options="tableOptions"
      :table-data="tableData"
      :table-loading="tableLoading"
      :data-total="page.total"
      :page="page"
      @page-current-change="onLoad"
      @page-size-change="onLoad"
      @page-refresh-change="onLoad"
      @grid-row-detail-click="rowDetail"
      @row-remove="rowRemove"
      @row-edit="rowEdit"
    >
      <template #customBtn="{row}">
        <el-button
          v-if="row.status != 2"
          type="text"
          size="small"
          @click="rowValid(row)"
        >{{ $t('cip.cmn.btn.validBtn') }}
        </el-button>
      </template>
    </grid-layout>
  </div>
</template>

<script>

import Template from "../../message/template/list";

let baseUrl = '/api/sinoma-system/dict-biz/dictionary?code='
import {mapGetters} from "vuex";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";

import {
  remove,
  getList,
  getValid
} from '@/api/system/params'

export default {
  name: "params",
  components: {
    Template,
    GridLayout,
    HeadLayout,
  },
  data() {
    return {
      selectionList: [],
      tableLoading: true,
      tableData: [],
      dataTotal: null,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0

      },
    };
  },
  computed: {
    ...mapGetters(["permission"]),
    searchColumns() {
      return [
        // {
        //   prop: "paramType",
        //   span: 4,
        //   maxlength: 20,
        //   minlength: 2,
        //   placeholder: this.$t("cip.plat.sys.params.field.paramType"),
        // },
        {
          prop: "paramType",
          type: 'select',
          dataType: 'string',
          span:4,
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=system_type_param",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          placeholder: this.$t(`cip.plat.sys.params.field.paramName`)
        },
      ]
    },
    tableOptions() {
      return {
        height: 0,
        customAddBtn: true,
        column: [
          //编码code
          //   {
          //     label: this.$t('sinoma.system.params.paramCode'),
          //     prop: 'paramCode',
          //     overHidden: true,
          //   },
          {
            label: this.$t('cip.plat.sys.params.field.paramName'),
            prop: 'paramName',
            overHidden: true,
            align: "left",
            width: 240,
          },

          {
            label: this.$t('cip.plat.sys.params.field.paramType'),
            prop: 'paramType',
            type: 'select',
            align: "center",
            dataType: "string",
            width: 240,
            dicUrl: `${baseUrl}system_type_param`,
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            overHidden: true,
          },
          {
            label: this.$t('cip.plat.sys.params.field.paramValue'),
            prop: 'paramValue',
            overHidden: true,
            align: "left",
            width: 460,
          },
          {
            label: this.$t('cip.plat.sys.params.field.status'),
            prop: 'status',
            align: "center",
            labelWidth: 130,
            width: 130,
            // dicData: [
            //   {
            //     label: this.$t('cip.plat.sys.params.field.enable'),
            //     value: 2
            //   },
            //   {
            //     label: this.$t('cip.plat.sys.params.field.disable'),
            //     value: 1
            //   }
            // ],
            dataType: 'number',
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=enable_type",
          },

          {
            label: this.$t('cip.plat.sys.params.field.remark'),
            prop: 'remark',
            align: "left",
            overHidden: true
          },
        ],
      }
    },
    gridRowBtn() {
      let   buttonBtn = [];
      if (this.permission.params_edit) {
        buttonBtn.push(
          {
            label: this.$t('cip.cmn.btn.editBtn'),
            emit: "row-edit",
            type: "text",
            icon: ""
          });
      }
      if (this.permission.params_delete) {
        buttonBtn.push(
          {
            label: this.$t('cip.cmn.btn.delBtn'),
            emit: "row-remove",
            type: "text",
            icon: ""
          })
      }
      return buttonBtn;
    },
    gridHeadBtn() {
      return []
    },
    headBtnOptions() {
      let   buttonBtn = [];
      if (this.permission.params_add) {
        buttonBtn.push(
          {
            label: this.$t("cip.cmn.btn.addBtn"),
            emit: "head-add-tabs",
            type: "button",
            icon: ""
          });
      }
      if (this.permission.params_delete) {
        buttonBtn.push(
          {
            label: this.$t('cip.cmn.btn.delBtn'),
            emit: "head-remove",
            type: "button",
            icon: ""
          });
      }
      return buttonBtn;
    },
  },
  mounted() {
    this.onLoad(this.page, {});
  },
  methods: {
    htmlEncode(html) {
      //1.首先动态创建一个容器标签元素，如DIV
      let temp = document.createElement("div");
      //2.然后将要转换的字符串设置为这个元素的innerText或者textContent
      (temp.textContent != undefined) ? (temp.textContent = html) : (temp.innerText = html);
      //3.最后返回这个元素的innerHTML，即得到经过HTML编码转换的字符串了
      let output = temp.innerHTML;
      temp = null;
      return output;
    },
    htmlDecode(text) {
      //1.首先动态创建一个容器标签元素，如DIV
      let temp = document.createElement("div");
      //2.然后将要转换的字符串设置为这个元素的innerHTML(ie，火狐，google都支持)
      temp.innerHTML = text;
      //3.最后返回这个元素的innerText或者textContent，即得到经过HTML解码的字符串了。
      let output = temp.innerText || temp.textContent;
      temp = null;
      return output;
    },
    selectionChange(list) {
      this.selectionList = list;
      console.log(this.selectionList)
    },
    headRemove() {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.selectWarning'));
        return;
      }
      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          let ids = this.selectionList.map(item => {
            return item.id
          }).join(',')
          return remove(ids);
        })
        .then(() => {
          // 刷新表格数据并重载
          this.data = [];
          this.parentId = 0;
          this.$refs.gridLayOut.selectionClear();
          // 表格数据重载
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess"),
          });
        });
    },
    headAddTabs() {
      this.$router.push({
        path: `/system/add`,
        query: {
          type: 'add',
          pageType: 'add',
        }
      })
    },
    rowEdit(row) {
      this.$router.push({
        path: `/system/add`,
        query: {
          id: row.id,
          type: 'edit',
          pageType: 'edit',
        }
      })
    },

    rowDetail(row) {
      this.$router.push({
        path: `/system/add`,
        query: {
          id: row.id,
          type: 'view',
          pageType: 'detail',
        }
      })
    },
    rowValid(row) {
      row.paramValue=this.htmlEncode(row.paramValue)
      getValid(row).then(res => {
        this.onLoad(this.page, {});
        this.$message({
          type: "success",
          message:  this.$t('cip.cmn.msg.success.delWarning')
        });
      })
    },
    rowRemove(row) {
      this.$confirm(this.$t('cip.cmn.msg.warning.enableSuccess'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return remove(row.id);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t('cip.cmn.msg.success.operateSuccess')
          });
        });
    },
    gridHeadSearch(query) {
      this.onLoad(this.page, query)
    },
    gridHeadEmpty(query) {
      this.onLoad(this.page, query)
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      getList(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.$refs.gridHeadLayout.searchForm)
      ).then(res => {
        const data = res.data.data;
        this.$refs.gridLayOut.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
      });
    }
  }
};
</script>
<style>
</style>
